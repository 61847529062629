#preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 999999;
  transition: all 0.5s ease-out;

  .blobs-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate3d(-50%, -50%, 0);
  }

  @keyframes blobs-left {
    0% {
      margin-left: 20px;
    }

    33% {
      margin-top: -15px;
      margin-left: -20px;
    }

    62% {
      margin-top: 22px;
      margin-left: -5px;
    }

    75% {
      margin-top: 22px;
      margin-left: -5px;
    }

    94% {
      margin-left: 20px;
    }
  }

  @keyframes blobs-right {
    0% {
      margin-left: -20px;
    }

    33% {
      margin-top: 15px;
      margin-left: 20px;
    }

    62% {
      margin-top: -22px;
      margin-left: 5px;
    }

    75% {
      margin-top: -22px;
      margin-left: 5px;
    }

    94% {
      margin-left: -20px;
    }
  }

  .blobs {
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate3d(-50%, 0, 0);
    filter: url("#goo");
    margin-left: 20px;
    animation: blobs-left cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;

    &.right-bottom {
      transform: translate3d(-50%, -50%, 0) rotate(180deg);
      margin-top: -40px;
      margin-left: -20px;
      animation: blobs-right cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;
    }
  }

  $dist-x: 30px;
  $dist-y: 30px;

  @keyframes blob-left-top-anim {
    0% {
      transform: translate(0, 0);
    }

    33% {
      transform: translate(-$dist-x, $dist-y);
    }

    62% {
      transform: scale(0.65) translate(4px, 16px);
    }

    75% {
      transform: scale(0.65) translate(4px, 16px);
    }

    94% {
      transform: translate(0, 0);
    }
  }

  @keyframes blob-right-top-anim {
    0% {
      transform: translate(0, 0);
    }

    33% {
      transform: translate($dist-x, -$dist-y);
    }

    66% {
      transform: scale(0.65) translate(25px, -85px);
    }

    75% {
      transform: scale(0.65) translate(25px, -85px);
    }

    96% {
      transform: translate(0, 0);
    }
  }

  @keyframes blob-left-bottom-anim {
    0% {
      transform: translate(0, 0);
    }

    33% {
      transform: translate(-$dist-x, $dist-y);
    }

    62% {
      transform: translate(-$dist-x, -$dist-y);
    }

    75% {
      transform: translate(-$dist-x, -$dist-y);
    }

    98% {
      transform: translate(0, 0);
    }
  }

  .blob {
    position: absolute;
    background: black;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    line-height: 100px;
    text-align: center;
    color: white;
    font-size: 40px;
    border-radius: 100%;
    margin-top: -50px;
    margin-left: -50px;
    animation: blob-left-top-anim cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;

    &.main {
      background: $main-color;
    }

    &.secondary {
      background: $secondary-color;
    }

    &.hidden {
      background: transparent;
    }

    &:nth-child(2) {
      animation-name: blob-left-bottom-anim;
    }

    &:nth-child(3) {
      animation-name: blob-right-top-anim;
    }
  }
}
